import React from 'react';

import SongkickIcon from '../Icons/SongkickIcon';

import Avatar from '@bit/mui-org.material-ui.avatar';

export default function SongkickVenueAvatar(imageUrl) {
  if (imageUrl == null) {
    // TODO this looks terrible with the grey background. fix.
    return <Avatar><SongkickIcon/></Avatar>;
  } else {
    return <Avatar alt="Songkick venue source" src={imageUrl} />;
  }
}
