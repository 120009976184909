import React, { useRef } from 'react';
import { Mutation } from "react-apollo";
import { AddSpotifyPlaylistSourceMutation } from "./operations.graphql";
import { SpotifyPlaylistSourceSubscriptionsQuery } from "../../graphql/operations.graphql";

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { errorMsg, isError } from '../../lib/errors.js';

var _ = require('lodash');

class AddSpotifyPlaylistSourceDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleClose = props.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  render () {
    const open = this.props.open;

    return (
      <Mutation
        mutation={AddSpotifyPlaylistSourceMutation}
        refetchQueries={() => {
          return [{
             query: SpotifyPlaylistSourceSubscriptionsQuery
          }]
        }}
        onCompleted={(data) => {
          if (_.get(data, 'addSpotifyPlaylistSource.spotifyPlaylistSource.playlistId')) {
            this.handleClose();
          }
        }}
      >
        {(addSpotifyPlaylistSource, { loading, error, data }) => {
          return (
            <form>
              <Dialog open={open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Gimme a Spotify playlist link to keep an eye on.
                  </DialogContentText>
                  <TextField
                    autoFocus
                    error={isError(error, data, 'addSpotifyPlaylistSource.errors')}
                    helperText={errorMsg(error, data, 'addSpotifyPlaylistSource.errors')}
                    margin="dense"
                    id="url"
                    label="Spotify playlist url"
                    type="text"
                    fullWidth
                    value={this.state.value}
                    onChange={this.handleChange}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                          event.preventDefault();
                          addSpotifyPlaylistSource({
                            variables: { playlistUrl: this.state.value }
                          });
                          this.setState({value: ''});
                      }
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button color="primary" onClick={(e) => {
                    event.preventDefault();
                    addSpotifyPlaylistSource({
                      variables: { playlistUrl: this.state.value }
                    });
                    this.setState({value: ''});
                  }}>
                    Subscribe
                  </Button>
                </DialogActions>
              </Dialog>
            </form>
          )
        }}
      </Mutation>
    );
  }
};

export default AddSpotifyPlaylistSourceDialog;
