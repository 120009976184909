import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Account from '../Account';
import User from '../User';
import LoggedOutLanding from '../LoggedOutLanding';
import LoggedInLanding from '../LoggedInLanding';
import ManageSpotifyPlaylistSource from '../ManageSpotifyPlaylistSource';
import ManageSongkickVenueSource from '../ManageSongkickVenueSource';
import SpotifyPlaylist from '../SpotifyPlaylist';
import Tracklist from '../Tracklist';
import Layout from '../Layout';

import { makeStyles } from '@material-ui/core/styles';

import { UserQuery } from "./operations.graphql";

import { Query } from 'react-apollo';

const useStyles = makeStyles((theme) => ({
  home: {
    height: '100vh',
  },
}));

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
          rest["me"] ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default function App() {
  

  return (
    <Query query={UserQuery}>
      {({ data, loading }) => {
        if (loading) return <p>"Loading"</p>;
        if (data === undefined) return <p>ERROR</p>;

        return (
          <Router>    
            <Switch>
              <PrivateRoute me={data.me} path="/source/spotify/manage/:playlistId">
                <Layout me={data.me}>
                  <SpotifyPlaylist/>
                </Layout>
              </PrivateRoute>
              <PrivateRoute me={data.me} path="/source/spotify/manage">
                <Layout me={data.me}>
                  <ManageSpotifyPlaylistSource/>
                </Layout>
              </PrivateRoute>
              <PrivateRoute me={data.me} path="/source/songkick/manage">
                <Layout me={data.me}>
                  <ManageSongkickVenueSource/>
                </Layout>
              </PrivateRoute>
              <PrivateRoute me={data.me} path="/account">
                <Layout me={data.me}>
                  <Account/>
                </Layout>
              </PrivateRoute>
              <Route path="/onboarding">
                <Layout me={data.me}>
                  <LoggedInLanding/>
                </Layout>
              </Route>
              <Route path="/user/:uid">
                <Layout>
                  <User/>
                </Layout>
              </Route>
              <Route path="/">
                <Layout me={data.me}>
                  <Home data={data}/>
                </Layout>
              </Route>
            </Switch>
          </Router>
        );
      }}
    </Query>
  );
}

export function Home({data}) {
  const classes = useStyles();
  if (data == null || data.me == null) {
    return <LoggedOutLanding/>;
  } else if (data.me.subscriptionsCount > 0) {
    return (
      <div className={classes.home}>
        <Tracklist playlist_id={data.me.destinationPlaylistId}/>
      </div>
    );
  } else {
    return <Redirect
      to={{
        pathname: "/onboarding",
      }}
    />;
  }
};
