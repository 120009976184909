import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function Account() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      This would contain login / logout and stuff
    </div>
  );
};
