import React from 'react';

import {
  useParams
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';

import Tracklist from '../Tracklist';

const useStyles = makeStyles((theme) => ({
  playlist: {
    height: '100vh',
  },
}));

export default function SpotifyPlaylist() {
  const classes = useStyles();
  let { playlistId } = useParams();
  // TODO should have some info on when we last fetched the playlist here

  return (
    <div className={classes.playlist}>
      <Tracklist playlist_id={playlistId}/>
    </div>
  );
}
