var _ = require('lodash');

export function isError(error, data, error_path) {
  if (error && !_.isEmpty(error.graphQLErrors)) return true;
  if (error && error.networkError) return true;
  if (!data) return false;
  if (_.get(data, error_path)) return true;

  return false;
}

export function errorMsg(error, data, error_path) {
  if (error && !_.isEmpty(error.graphQLErrors)) {
    return error.graphQLErrors.join('; ');
  }

  // this is things like 500 errors from the server
  if (error && error.networkError) {
    return error.networkError.message;
  }

  const errors = _.get(data, error_path);
  if (errors) {
    return errors.fullMessages.join('; ');
  }

  return '';
}