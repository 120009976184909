import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import SpotifyPlayer from 'react-spotify-player';

const useStyles = makeStyles((theme) => ({
  spotify: {
    height: '95%',
  },
}));
 
// size may also be a plain string using the presets 'large' or 'compact'
const size = {
  width: '100%',
  minWidth: '300px',
  height: '100%',
};
const view = 'list'; // or 'coverart'
const theme = 'white'; // or 'white'


export default function Tracklist({playlist_id}) {
  const classes = useStyles();
  const url = 'spotify:playlist:'+playlist_id;

  return (
    <Grid container className={classes.spotify} justify="center" alignItems="center">
      <Grid
        item
        className={classes.spotify}
        xs={12}
        lg={8}
      >
        <SpotifyPlayer
          uri={url}
          size={size}
          view={view}
          theme={theme}
        />
      </Grid>
    </Grid>
  );
};
