import React from 'react';
import {
  Link
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import SpotifyPlaylistSourceSuggestionsList from '../SpotifyPlaylistSourceSuggestionsList';
import SongkickVenueSourceSuggestionsList from '../SongkickVenueSourceSuggestionsList';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  space: {
    padding: 10,
    margin: 10,
  },
}));

/**
 * For when the user is logged in, but haven't set anything up yet.
 */
export default function LoggedInLanding() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3} justify="center">
        <Grid container item direction="column" xs={6}>
          <Typography align="center" variant="h4" className={classes.space}>
            Getting started
          </Typography>

          <Typography variant="body1" className={classes.space}>
            Let's figure out what to fill your Inbox with.
          </Typography>

          <Typography variant="body1" className={classes.space}>
            Starting with Spotify we picked a couple of suggested playlists you can choose from.
            Or you can click the Spotify logo in the top right to dive deeper.
          </Typography>

          <SpotifyPlaylistSourceSuggestionsList/>

          <Typography variant="body1" className={classes.space}>
            If you're a live music fan you can track the artists playing at your favourite venues.
          </Typography>
          <Typography variant="body1" className={classes.space}>
            We'll add the headliner's top Spotify track to your inbox.
          </Typography>

          <SongkickVenueSourceSuggestionsList/>

          <Button color="primary" href="/" className={classes.space}>
            Show me my Inbox
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
