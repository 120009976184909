import * as React from "react";

function SongkickIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 399 399" {...props}>
      <defs>
        <path id="prefix__a" d="M.06.257h398.648V399H.06V.257z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <path
          d="M108.803 312.29c-30.837 0-56.012-5.66-76.373-26.638l31.693-31.689c10.456 10.463 28.843 14.145 44.96 14.145 19.523 0 28.849-6.501 28.849-18.1 0-4.814-1.116-8.784-3.951-11.908-2.544-2.543-6.787-4.533-13.293-5.379l-24.327-3.384c-17.826-2.55-31.395-8.496-40.448-17.83-9.344-9.614-13.861-23.191-13.861-40.439 0-36.775 27.713-63.661 73.542-63.661 28.853 0 50.638 6.795 67.886 24.051l-31.117 31.115c-12.727-12.733-29.42-11.883-38.184-11.883-17.253 0-24.324 9.908-24.324 18.669 0 2.552.849 6.222 3.966 9.336 2.54 2.55 6.775 5.092 13.857 5.944l24.315 3.394c18.114 2.541 31.123 8.194 39.614 16.69 10.748 10.466 14.975 25.45 14.975 44.154 0 41.018-35.342 63.413-77.779 63.413zM345.746 44.314c-27.075-20.269-57.137-33.877-95.057-40.123v180.686l54.17-75.422h59.242l-68.227 95.056s15.689 25.066 25.059 39.956c9.6 14.987 18.774 19.063 32.679 19.063 4.157 0 4.151.022 9.418.022v46.752l-14.476.036c-30.039 0-48.138-5.477-61.709-26.229-8.429-13.422-36.156-56.974-36.156-56.974v82.979h-49.505V.285c-.621-.003-1.208-.028-1.831-.028-63.998 0-108.132 15.418-146.369 44.057C-2.19 85.604.012 140.674.012 199.579c0 58.942-2.202 114.08 52.972 155.376C91.221 383.596 135.355 399 199.353 399c63.988 0 108.139-15.404 146.393-44.045 55.162-41.296 52.95-96.434 52.95-155.376 0-58.905 2.212-113.975-52.95-155.265z"
          fill="#F80046"
          mask="url(#prefix__b)"
        />
      </g>
    </svg>
  );
}

const MemoSongkickIcon = React.memo(SongkickIcon);
export default MemoSongkickIcon;
