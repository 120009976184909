import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from "react-router-dom";
import Grid from '@material-ui/core/Grid';

import Typography from '@bit/mui-org.material-ui.typography';

import SubscriptionsQuery from './operations.graphql';

import { SongkickVenueSourceSubscriptionsListCore } from '../SongkickVenueSourceSubscriptionsList';
import { SpotifyPlaylistSourceSubscriptionsListCore } from '../SpotifyPlaylistSourceSubscriptionsList';

import { Query } from 'react-apollo';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    zIndex: 100, // no idea if this is how this is done
  },
  space: {
    padding: 10,
    margin: 10,
  },
}));

export default function User() {
  const classes = useStyles();
  let { uid } = useParams();
  
  return (
    <Query query={SubscriptionsQuery} variables={{ spotifyUsername: uid }}>
      {({ data, loading }) => {
        if (loading) return <p>"Loading"</p>;
        if (data === undefined) return <p>ERROR</p>;

        return data.user && data.user.subscriptions.length ? (
          <React.Fragment>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <Typography variant="subtitle1" className={classes.space}>
                  {uid}
                </Typography>
                {/* this is weird, what's the react/graphql best practice? */}
                <SpotifyPlaylistSourceSubscriptionsListCore 
                  disableRemove={true}
                  subscriptions={ _.filter(data.user.subscriptions, s => s.sourceType == "SpotifyPlaylistSource") }/>
                <SongkickVenueSourceSubscriptionsListCore
                  subscriptions={ _.filter(data.user.subscriptions, s => s.sourceType == "SongkickVenueSource") }/>
              </Grid>
            </Grid>
          </React.Fragment>
        ) : (
          <div>
            <Typography variant="subtitle2" className={classes.space}>
              User not found
            </Typography>
          </div>
        )
      }}
    </Query>
  );
}
