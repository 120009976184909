import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import Typography from '@bit/mui-org.material-ui.typography';

import AddSpotifyPlaylistSourceDialog from '../AddSpotifyPlaylistSourceDialog';
import SpotifyPlaylistSourceSuggestionsList from '../SpotifyPlaylistSourceSuggestionsList';
import SpotifyPlaylistSourceSubscriptionsList from '../SpotifyPlaylistSourceSubscriptionsList';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    zIndex: 100, // no idea if this is how this is done
  },
  space: {
    padding: 10,
    margin: 10,
  },
}));

export default function ManageSpotifyPlaylistSource() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  return (
    <React.Fragment>
      <AddSpotifyPlaylistSourceDialog open={open} handleClose={handleClose}/>
      <Fab color="primary" aria-label="add" className={classes.fab}>
        <AddIcon onClick={handleClickOpen}/>
      </Fab>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <Typography variant="subtitle1" className={classes.space}>
            Your playlist subscriptions
          </Typography>
          <SpotifyPlaylistSourceSubscriptionsList/>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography variant="subtitle1" className={classes.space}>
            Playlists you might like to subscribe to
          </Typography>
          <SpotifyPlaylistSourceSuggestionsList/>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
