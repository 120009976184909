import React from 'react';
import { makeStyles } from '@bit/mui-org.material-ui.styles';
import List from '@bit/mui-org.material-ui.list';
import ListItem from '@bit/mui-org.material-ui.list-item';
import Divider from '@bit/mui-org.material-ui.divider';
import ListItemText from '@bit/mui-org.material-ui.list-item-text';
import ListItemAvatar from '@bit/mui-org.material-ui.list-item-avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import SpotifyPlaylistAvatar from "../SpotifyPlaylistAvatar";

import { Query } from 'react-apollo';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from "react-router-dom";

import { SpotifyPlaylistSourceSubscriptionsQuery } from "../../graphql/operations.graphql";
import { SpotifyPlaylistSourceSuggestionsQuery } from "../SpotifyPlaylistSourceSuggestionsList/operations.graphql";
import { UnsubscribeFromSourceMutation } from "./operations.graphql";

import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  space: {
    padding: 10,
    margin: 10,
  },
}));

export function SpotifyPlaylistSourceSubscriptionsListCore({ disableRemove, subscriptions }) {
  if (!subscriptions) return null;

  const classes = useStyles();

  return (
    <List className={classes.root}>
      {subscriptions.map(source_subscription => (
        <React.Fragment key={source_subscription.id}>
          <SpotifyPlaylistSourceSubscriptionsListItem
            disableRemove={disableRemove}
            key={source_subscription.id}
            subscription_id={source_subscription.id}
            source={source_subscription.source} />
          <Divider variant="inset" component="li" />
        </React.Fragment>
      ))}
    </List>
  );
}

export default function SpotifyPlaylistSourceSubscriptionsList() {
  const classes = useStyles();

  return (
    <Query query={SpotifyPlaylistSourceSubscriptionsQuery}>
      {({ data, loading }) => {
        if (loading) return <p>"Loading"</p>;
        if (data === undefined) return <p>ERROR</p>;

        return data.me && data.me.subscriptions.length ? (
          <SpotifyPlaylistSourceSubscriptionsListCore disableRemove={false} subscriptions={data.me.subscriptions}/>
        ) : (
          <div>
            <Typography variant="subtitle2" className={classes.space}>
              You have not subscribed to any Spotify playlists yet
            </Typography>
          </div>
        )
      }}
    </Query>
  );
}

const listClick = (history, playlistId) => {
  history.push("/source/spotify/manage/"+playlistId);
};

function SpotifyPlaylistSourceSubscriptionsListItem({disableRemove, subscription_id, source}) {
  const history = useHistory();

  const [unsubscribeFromSource, { data }] = useMutation(
    UnsubscribeFromSourceMutation,
    {
      refetchQueries: [
        {query: SpotifyPlaylistSourceSubscriptionsQuery},
        {query: SpotifyPlaylistSourceSuggestionsQuery}
      ]
    }
  );
  const itemAvatar = SpotifyPlaylistAvatar(source.imageUrl);
  
  return (
    <ListItem>
      <ListItemAvatar onClick={(e) => listClick(history, source.playlistId)}>
        {itemAvatar}
      </ListItemAvatar>
      <ListItemText primary={source.playlistName} onClick={(e) => listClick(history, source.playlistId)}/>
      {disableRemove ? null : (
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={e => {
              e.preventDefault();
              unsubscribeFromSource({variables: { subscriptionId: subscription_id }});
            }}
          >
            <DeleteIcon/>
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  )
}