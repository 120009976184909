import React from 'react';
import { makeStyles } from '@bit/mui-org.material-ui.styles';
import List from '@bit/mui-org.material-ui.list';
import ListItem from '@bit/mui-org.material-ui.list-item';
import Divider from '@bit/mui-org.material-ui.divider';
import ListItemText from '@bit/mui-org.material-ui.list-item-text';
import ListItemAvatar from '@bit/mui-org.material-ui.list-item-avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@bit/mui-org.material-ui.typography';

import { useMutation } from '@apollo/react-hooks';
import { Query } from 'react-apollo';

import SpotifyPlaylistAvatar from "../SpotifyPlaylistAvatar";

import { SpotifyPlaylistSourceSubscriptionsQuery } from "../../graphql/operations.graphql";
import { SpotifyPlaylistSourceSuggestionsQuery } from "./operations.graphql";
import { SubscribeToSourceMutation } from "./operations.graphql";

import AddBoxIcon from '@material-ui/icons/AddBox';


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  space: {
    padding: 10,
    margin: 10,
  },
}));

export default function SpotifyPlaylistSourceSuggestionsList() {
  const classes = useStyles();

  return (
    <Query query={SpotifyPlaylistSourceSuggestionsQuery}>
      {({ data, loading }) => {
        if (loading) return <p>"Loading"</p>;
        if (data === undefined) return <p>ERROR</p>;

        return data.sources && data.sources.length ? (
          <List className={classes.root}>
            {data.sources.map(source => (
              <React.Fragment key={source.id}>
                <SpotifyPlaylistSourceSuggestionsListItem key={source.id} source={source} />
                <Divider variant="inset" component="li" />
              </React.Fragment>
            ))}
          </List>
        ) : (
          <div>
            <Typography variant="subtitle2" className={classes.space}>
              We ran out of Spotify playlist suggestions for you. You can find others in Spotify and add them manually.
            </Typography>
          </div>
        )
      }}
    </Query>
  );
}

function SpotifyPlaylistSourceSuggestionsListItem({source}) {
  const [subscribeToSource, { data }] = useMutation(
    SubscribeToSourceMutation,
    {
      refetchQueries: [
        {query: SpotifyPlaylistSourceSuggestionsQuery},
        {query: SpotifyPlaylistSourceSubscriptionsQuery}
      ]
    }
  );
  const itemAvatar = SpotifyPlaylistAvatar(source.imageUrl);

  return (
    <ListItem>
      <ListItemAvatar>
        {itemAvatar}
      </ListItemAvatar>
      <ListItemText primary={source.playlistName}/>
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="subscribe"
          onClick={e => {
            e.preventDefault();
            subscribeToSource({variables: { sourceId: source.id, sourceType: "SpotifyPlaylistSource" }});
          }}
        >
          <AddBoxIcon/>
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}