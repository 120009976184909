import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import SvgIcon from '@material-ui/core/SvgIcon';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import SongkickIcon from '../Icons/SongkickIcon';
import SpotifyIcon from '../Icons/SpotifyIcon';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import { Mutation } from "react-apollo";

import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  toolbar: {
  },
  paper: {
    // header at 64 and margin at 8px top and 8px bottom = 80
    // i'm sure there's some better way to do this
    minHeight: "calc(100vh - 80px)"
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export default function Layout(props) {
  const classes = useStyles();
  const history = useHistory();

  const playlistClick = () => {
    history.push("/");
  };

  const spotifyClick = () => {
    history.push("/source/spotify/manage");
  };

  const songkickClick = () => {
    history.push("/source/songkick/manage");
  };

  const accountClick = (me) => {
    if (me) {
      history.push("/account");
    } else {
      history.push("/signin");
    }
  };
   
  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6">
          <Link href="/" color="inherit">
            Inbox
          </Link>
        </Typography>

        <IconButton
            className={classes.menuButton}
            color="inherit"
            aria-label="open playlist"
            onClick={()=>playlistClick()}
          >
          <PlaylistPlayIcon/>
        </IconButton>

        <div className={classes.grow} />

        {props.me ? <div>
          <IconButton onClick={()=>spotifyClick()}><SvgIcon><SpotifyIcon/></SvgIcon></IconButton>
          <IconButton onClick={()=>songkickClick()}><SvgIcon><SongkickIcon/></SvgIcon></IconButton>
          </div> : <div></div>}
        <IconButton onClick={()=>accountClick(props.me)}><PersonPinIcon/></IconButton>
      </Toolbar>

      <Paper className={classes.paper}>
        {props.children}
      </Paper>        
    </div>
  );
};
