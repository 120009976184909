import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  space: {
    padding: 10,
    margin: 10,
  },
}));

export default function LoggedOutLanding() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3} justify="center">
        <Grid container item direction="column" xs={6}>
          <Typography align="center" variant="h4" className={classes.space}>
            It's a music inbox of sorts
          </Typography>

          <Typography variant="body1" className={classes.space}>
            We'll vacuum Spotify playlists and Songkick's venue listings for new music and use it to keep your inbox up to date.
          </Typography>

          <Typography variant="body1" className={classes.space}>
            First things first. In order for us to create your Inbox within Spotify, please log in and allow us playlist permissions.
          </Typography>
        
          <Grid container justify="center" className={classes.space}>
            <Button size="large" variant="contained" color="primary" href="/users/auth/spotify">
              Log in with Spotify
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
