import React from 'react';
import { makeStyles } from '@bit/mui-org.material-ui.styles';
import List from '@bit/mui-org.material-ui.list';
import ListItem from '@bit/mui-org.material-ui.list-item';
import Divider from '@bit/mui-org.material-ui.divider';
import ListItemText from '@bit/mui-org.material-ui.list-item-text';
import ListItemAvatar from '@bit/mui-org.material-ui.list-item-avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import SongkickVenueAvatar from "../SongkickVenueAvatar";

import { Query } from 'react-apollo';
import { useMutation } from '@apollo/react-hooks';

import { SongkickVenueSourceSubscriptionsQuery } from "./operations.graphql";
import { SongkickVenueSourceSuggestionsQuery } from "../SongkickVenueSourceSuggestionsList/operations.graphql";
import { UnsubscribeFromSourceMutation } from "./operations.graphql";

import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  space: {
    padding: 10,
    margin: 10,
  },
}));

export function SongkickVenueSourceSubscriptionsListCore({ disableRemove, subscriptions }) {
  const classes = useStyles();

  if (!subscriptions) return null;

  return (
    <List className={classes.root}>
      {subscriptions.map(source_subscription => (
        <React.Fragment key={source_subscription.id}>
          <SongkickVenueSourceSubscriptionsListItem
            disableRemove={disableRemove}
            key={source_subscription.id}
            subscription_id={source_subscription.id}
            source={source_subscription.source} />
          <Divider variant="inset" component="li" />
        </React.Fragment>
      ))}
    </List>
  );
}

export default function SongkickVenueSourceSubscriptionsList() {
  const classes = useStyles();

  return (
    <Query query={SongkickVenueSourceSubscriptionsQuery}>
      {({ data, loading }) => {
        if (loading) return <p>"Loading"</p>;
        if (data === undefined) return <p>ERROR</p>;

        return data.me && data.me.subscriptions.length ? (
          <SongkickVenueSourceSubscriptionsListCore subscriptions={data.me.subscriptions}/>
        ) : (
          <div>
            <Typography variant="subtitle2" className={classes.space}>
              You have not subscribed to any Songkick venues yet
            </Typography>
          </div>
        )
      }}
    </Query>
  );
}

function SongkickVenueSourceSubscriptionsListItem({disableRemove, subscription_id, source}) {
  const [unsubscribeFromSource, { data }] = useMutation(
    UnsubscribeFromSourceMutation,
    {
      refetchQueries: [
        {query: SongkickVenueSourceSubscriptionsQuery},
        {query: SongkickVenueSourceSuggestionsQuery}
      ]
    }
  );
  const itemAvatar = SongkickVenueAvatar(source.imageUrl);
  
  return (
    <ListItem>
      <ListItemAvatar>
        {itemAvatar}
      </ListItemAvatar>
      <ListItemText primary={`${source.venueName}, ${source.cityName}`}/>
      {disableRemove ? null : (
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={e => {
              e.preventDefault();
              unsubscribeFromSource({variables: { subscriptionId: subscription_id }});
            }}
          >
            <DeleteIcon/>
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  )
}