import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Fab from '@material-ui/core/Fab';
import Link from '@material-ui/core/Link';
import AddIcon from '@material-ui/icons/Add';
import SvgIcon from '@material-ui/core/SvgIcon';

import PoweredBySongkickIcon from '../Icons/PoweredBySongkickIcon';

import Typography from '@bit/mui-org.material-ui.typography';

import AddSongkickVenueSourceDialog from '../AddSongkickVenueSourceDialog';
import SongkickVenueSourceSuggestionsList from '../SongkickVenueSourceSuggestionsList';
import SongkickVenueSourceSubscriptionsList from '../SongkickVenueSourceSubscriptionsList';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    zIndex: 100, // no idea if this is how this is done
  },
  powered: {
    height: 100,
    width: 200,
    position: 'absolute',
    bottom: theme.spacing(0),
    right: theme.spacing(0),
  },
  space: {
    padding: 10,
    margin: 10,
  },
}));

export default function ManageSongkickVenueSource() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  return (
    <div className={classes.root}>
      <AddSongkickVenueSourceDialog open={open} handleClose={handleClose}/>
      <Fab color="primary" aria-label="add" className={classes.fab}>
        <AddIcon onClick={handleClickOpen}/>
      </Fab>

      <Link href="https://www.songkick.com">
        <SvgIcon className={classes.powered}><PoweredBySongkickIcon/></SvgIcon>
      </Link>
      
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <Typography variant="subtitle1" className={classes.space}>
            Your venue subscriptions
          </Typography>
          <SongkickVenueSourceSubscriptionsList/>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography variant="subtitle1" className={classes.space}>
            Venues you might like to subscribe to
          </Typography>
          <SongkickVenueSourceSuggestionsList/>
        </Grid>
      </Grid>
    </div>
  );
}
