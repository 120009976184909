import React from 'react';

import SpotifyIcon from '../Icons/SpotifyIcon';

import Avatar from '@bit/mui-org.material-ui.avatar';

export default function SpotifyPlaylistAvatar(imageUrl) {
  if (imageUrl == null) {
    // TODO this looks terrible with the grey background. fix.
    return <Avatar><SpotifyIcon/></Avatar>;
  } else {
    return <Avatar alt="Spotify playlist source" src={imageUrl} />;
  }
}
